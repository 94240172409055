import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { toast } from 'react-toastify';
import { LoginForm as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Flex from 'components/common/Flex';
import { useHistory, useLocation } from 'react-router-dom';
import { gtm_event_userRegistered } from 'services/externals/google/googleTagManager';
import { hs_sendMailAddress } from 'services/externals/hubspot';
import { identifyMixpanelUser, trackEvent } from 'services/externals/mixpanel';
import { toltSignup } from 'services/externals/tolt';
import 'assets/scss/custom/login.scss';
import 'assets/scss/custom/iosGoogleButton.scss';

const LoginForm = ({ hasLabel, getInfo }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const location = useLocation();
  const fullPath = location.pathname + location.search;
  const queryParameters = new URLSearchParams(window.location.search);
  const referralInURL = queryParameters.get('ref') || '';
  const utm_campaign = queryParameters.get('utm_campaign')
    ? queryParameters.get('utm_campaign').trim()
    : '';

  // State
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const routerHistory = useHistory();

  const handleSubmit = async e => {
    e.preventDefault();

    // Generate Recaptcha Token & Login
    window.grecaptcha.enterprise.ready(async () => {
      const recaptcha_token = await window.grecaptcha.enterprise.execute(
        '6LcE9igoAAAAAJ2plIdhi4bvhKr3hn95kyNmxTqc',
        { action: 'login' }
      );

      let form = new FormData();
      form.append('username', formData['username'].toLowerCase());
      form.append('password', formData['password']);
      form.append('recaptcha_token', recaptcha_token);
      let loginResponse = await endpointInterface(
        lang,
        'backend',
        'loginPage',
        'post',
        true,
        form
      );
      if (loginResponse.validResponse) {
        // Send mail address to hubspot
        hs_sendMailAddress(
          loginResponse.data?.email || formData['username'].toLowerCase(),
          fullPath
        );
        // Identify user in mixpanel
        identifyMixpanelUser(
          loginResponse.data?.tracking_id || 0,
          loginResponse.data?.email || formData['username']
        );
        await getInfo();
        routerHistory.push('/');
      } else {
        toast.error(loginResponse.responseMessage, { closeButton: false });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    });
  };

  // Function called by the iOS app when executing the Google login
  const iosGoogleLogin = token => {
    const responseObject = { credential: token };
    sendGoogleToken(responseObject);
  };

  window.iosGoogleLogin = iosGoogleLogin;

  const sendGoogleToken = async response => {
    let token = response['credential'];
    let form = new FormData();
    form.append('token', token);
    form.append('referral', referralInURL.trim());
    form.append('utm_campaign', utm_campaign);
    form.append('tolt_referral', window.tolt_referral || '');
    let loginResponse = await endpointInterface(
      lang,
      'backend',
      'googleLogin',
      'post',
      true,
      form
    );
    let userWasRegistered = false;
    if (
      loginResponse.data.already_registered != undefined &&
      loginResponse.data.already_registered === true
    )
      userWasRegistered = true;
    if (loginResponse.validResponse) {
      let data = await getInfo(true);
      // Send mail address to hubspot
      hs_sendMailAddress(loginResponse.data?.email || '', fullPath);
      if (!userWasRegistered) {
        // Send the user_registered GTM event
        gtm_event_userRegistered(data?.tracking_id || null);
        // send event in mixpanel
        trackEvent('signup', {
          method: 'google',
          mail: loginResponse.data?.email || formData.email
        });
        // send registration data to tolt
        toltSignup(loginResponse.data?.email || formData.email);
      }
      // identify user in mixpanel
      identifyMixpanelUser(
        loginResponse.data?.tracking_id || 0,
        loginResponse.data?.email || formData.email
      );
      routerHistory.push('/');
    } else {
      toast.error(loginResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // TODO: Get google client id from config file

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{textLang.email[lang]}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? textLang.email[lang] : ''}
          value={formData.username}
          name="username"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>
      <Flex className="justify-content-center">
        <Link className="fs--1 mb-0" to={`/forgot-password`}>
          {textLang.forgotPwd[lang]}
        </Link>
      </Flex>
      <Form.Group>
        <Flex className="justify-content-center">
          <Button
            type="submit"
            color="primary"
            className="mt-3 custom-button"
            disabled={!formData.username || !formData.password}
          >
            {textLang.login[lang]}
          </Button>
        </Flex>
      </Form.Group>
      <Flex className="justify-content-center mt-3 mb-3">
        {textLang.or[lang]}
      </Flex>
      <div className="d-flex justify-content-center">
        {localStorage.getItem('pwa_env') === 'ios' ? (
          <button
            className="gsi-material-button"
            style={{ width: '250px' }}
            onClick={e => {
              e.preventDefault();
              if (
                window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers['trigger-google-signin']
              ) {
                window.webkit.messageHandlers[
                  'trigger-google-signin'
                ].postMessage(null);
              } else {
                console.error('Google Sign-In handler is not available.');
              }
            }}
          >
            <div className="gsi-material-button-state"></div>
            <div className="gsi-material-button-content-wrapper">
              <div className="gsi-material-button-icon">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  style={{ display: 'block' }}
                >
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  ></path>
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  ></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span className="gsi-material-button-contents">
                {textLang.googleButtonText[lang]}
              </span>
              <span style={{ display: 'none' }}>
                {textLang.googleButtonText[lang]}
              </span>
            </div>
          </button>
        ) : (
          <GoogleOAuthProvider clientId="963417905135-ho3o0v3h0lurjmgp9kgkdqfunai4k1nm.apps.googleusercontent.com">
            <GoogleLogin
              text="signin_with"
              onSuccess={sendGoogleToken}
              onError={error => toast.error(error, { closeButton: false })}
              width="250px"
              useOneTap
            />
          </GoogleOAuthProvider>
        )}
      </div>
    </Form>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool,
  getInfo: PropTypes.func
};

export default LoginForm;
