import React, { useReducer, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';
import { sharedState } from 'staticData/common';
import { initializeDatabase } from 'services/cache/db';
import 'react-toastify/dist/ReactToastify.css';

const Main = props => {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: true,
    isReal: getItemFromStore('isReal', settings.isReal),
    visualType: getItemFromStore('visualType', settings.visualType),
    newsRead: getItemFromStore('newsRead', settings.newsRead),
    insightsShow: getItemFromStore('insightsShow', settings.insightsShow),
    proNotifications: getItemFromStore(
      'proNotifications',
      settings.proNotifications
    ),
    newRelease: getItemFromStore('newRelease', settings.newRelease),
    lang: getItemFromStore('lang', settings.lang),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false,
    event: getItemFromStore('event', settings.event),
    popUpInfo: getItemFromStore('popUpInfo', settings.popUpInfo),
    alertForBot: getItemFromStore('alertForBot', settings.alertForBot),
    savedBotParams: getItemFromStore('savedBotParams', {}),
    savedRiskForm: getItemFromStore('savedRiskForm', {}),
    bannerDashboard: getItemFromStore('bannerDashboard', null)
  };

  /** For downloading App */
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  window.addEventListener('beforeinstallprompt', e => {
    setDeferredPrompt(e);
  });

  const downloadApp = async () => {
    if (deferredPrompt !== null) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        setDeferredPrompt(null);
      }
    }
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'isReal',
          'visualType',
          'newsRead',
          'insightsShow',
          'newRelease',
          'lang',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle',
          'proNotifications',
          'event',
          'popUpInfo',
          'alertForBot',
          'savedBotParams',
          'savedRiskForm',
          'bannerDashboard'
        ].includes(key)
      }
    });
  };

  // for updating mainLayout component
  const [userInfos, setUserInfos] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);

  // Coin map values
  const coinValues = useRef({});
  const setRefCoinValues = value => {
    coinValues.current = value;
  };
  const coinPerc24h = useRef({});
  const setRefCoinPerc24h = value => {
    coinPerc24h.current = value;
  };

  // Update sharedState when config changes
  useEffect(() => {
    sharedState.config = configState;
  }, [configState]);

  // Initialize the database when the component is mounted
  useEffect(() => {
    const initializeDB = async () => {
      await initializeDatabase();
    };

    initializeDB();
  }, []);

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider
      value={{
        config,
        setConfig,
        deferredPrompt,
        downloadApp,
        userInfos,
        setUserInfos,
        loggedIn,
        setLoggedIn,
        coinValues,
        setRefCoinValues,
        coinPerc24h,
        setRefCoinPerc24h
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
