import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import 'assets/scss/custom/profileDropdown.scss';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { cleanCache } from 'services/cache';
import AppContext from 'context/Context';
import { firstProTier, secondProTier, traderProTier } from 'staticData/common';
import { googleLogout } from '@react-oauth/google';
import { useHistory } from 'react-router-dom';
import { resetUser } from 'services/externals/mixpanel';

const ProfileDropdown = ({ icon }) => {
  const {
    config: { lang },
    userInfos,
    setUserInfos,
    setLoggedIn
  } = useContext(AppContext);
  const routerHistory = useHistory();

  const handleLogout = async () => {
    await googleLogout();
    let logoutResponse = await endpointInterface(
      lang,
      'backend',
      'logoutPage',
      'post',
      true
    );
    if (logoutResponse.validResponse) {
      await cleanCache(0);
      setLoggedIn(false);
      setUserInfos({});
      resetUser();
      routerHistory.push('/login');
    } else {
      toast.error(logoutResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
  };

  return (
    <Dropdown className="d-none d-sm-block" navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        style={{
          height: '30px',
          background: 'transparent',
          borderColor: 'transparent',
          boxShadow: 'none'
        }}
        className="pe-0 ps-0 nav-link d-flex align-items-center"
      >
        <Avatar
          icon={icon}
          iconClass="fs-0"
          iconStyle={{ padding: '0.5rem 0.5rem 0.5rem 0' }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropdown-menu-card  dropdown-menu-end"
        style={{ right: '-0.2rem' }}
      >
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {userInfos.pro_tier && userInfos.pro_tier >= traderProTier ? (
            <Dropdown.Item
              className="fw-bold text-success"
              as={Link}
              to="/account/billings"
            >
              <FontAwesomeIcon icon="chart-bar" className="me-1" />
              <span>Private</span>
            </Dropdown.Item>
          ) : userInfos.pro_tier && userInfos.pro_tier === firstProTier ? (
            <Dropdown.Item
              className="fw-bold text-warning"
              as={Link}
              to="/account/billings"
            >
              <FontAwesomeIcon icon="crown" className="me-1" />
              <span>Explorer</span>
            </Dropdown.Item>
          ) : userInfos.pro_tier && userInfos.pro_tier === secondProTier ? (
            <Dropdown.Item
              className="fw-bold text-primary"
              as={Link}
              to="/account/billings"
            >
              <FontAwesomeIcon icon="rocket" className="me-1" />
              <span>Investor</span>
            </Dropdown.Item>
          ) : (
            <></>
          )}
          {userInfos.pro_tier > 0 && <Dropdown.Divider />}
          <Dropdown.Item as={Link} to="/account/profile" className="fw-bold">
            <FontAwesomeIcon icon="user" className="me-1" />
            <span style={{ paddingLeft: '4px' }}>{userInfos.username}</span>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/dashboard">
            <FontAwesomeIcon icon="chart-pie" className="me-1" />
            <span style={{ paddingLeft: '4px' }}>Dashboard</span>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/rewards">
            <FontAwesomeIcon icon="gift" className="me-1" />
            <span style={{ paddingLeft: '4px' }}>Referral</span>
          </Dropdown.Item>
          <Dropdown.Item
            className="custom-link-color"
            onClick={() => handleLogout()}
          >
            <FontAwesomeIcon icon="power-off" className="me-1" />
            <span style={{ paddingLeft: '4px' }}>Logout</span>
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

ProfileDropdown.propTypes = {
  icon: PropTypes.string.isRequired
};

export default ProfileDropdown;
