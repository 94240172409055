import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'context/Context';
import { topNavbarBreakpoint } from 'config';
import { useLocation } from 'react-router';
import 'assets/scss/custom/navbar.scss';
import { frontendRoutes } from 'router/routes';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { teamLevel, canShowRoute } from 'staticData/common';

const NavbarBottom = () => {
  const {
    config: { navbarPosition, lang },
    setConfig,
    userInfos
  } = useContext(AppContext);

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');
  const routerHistory = useHistory();
  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', false);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', false);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  // Choose NavbarVersion (starting from 0)
  const navbarVersion = 2;

  // data for navbar 1
  const filteredSXRoutes = frontendRoutes.filter(
    route =>
      route.inBottomNavbar &&
      route.inBottomLeft &&
      (!route.minLevel ||
        (route.minLevel && userInfos.level >= route.minLevel)) &&
      (!route.showWithPermission ||
        (route.showWithPermission &&
          canShowRoute(route.showWithPermission, userInfos)))
  );
  const filteredDXRoutes = frontendRoutes.filter(
    route =>
      route.inBottomNavbar &&
      route.inBottomRight &&
      (!route.minLevel ||
        (route.minLevel && userInfos.level >= route.minLevel)) &&
      (!route.showWithPermission ||
        (route.showWithPermission &&
          canShowRoute(route.showWithPermission, userInfos)))
  );
  const firstTwoSXRoutes = filteredSXRoutes.slice(0, 2);
  const firstTwoDXRoutes = filteredDXRoutes.slice(0, 2);
  // END data for navbar 1

  // data for navbar 2
  // navbar items for users
  let bottomArray = frontendRoutes
    .filter(item => item.bottomPosition !== undefined) // Filter items that have the 'bottomPosition' property
    .sort((a, b) => a.bottomPosition - b.bottomPosition); // Sort the items based on the 'bottomPosition' property

  // navbar items for TEAM Hodlie
  let TEAMbottomArray = frontendRoutes
    .filter(item => item.bottomTEAMPosition !== undefined) // Filter items that have the 'bottomTEAMPosition' property
    .sort((a, b) => a.bottomTEAMPosition - b.bottomTEAMPosition); // Sort the items based on the 'bottomTEAMPosition' property
  // END data for navbar 2

  const handleRouteClick = to => {
    routerHistory.push(to);
    handleBurgerMenu();
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <Navbar
      className={classNames(
        'navbar-custom  fs--1 fixed-bottom sticky-kit d-block d-sm-none',
        {
          '': showDropShadow && !isChat,
          'pb-4': localStorage.getItem('pwa_env') === 'ios'
        }
      )}
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }
    >
      <Row style={{ width: '100%', margin: 0 }}>
        {/* VERSION 2: STYLE LIKE INSTAGRAM */}
        {navbarVersion === 2 && (
          <>
            {userInfos.level < teamLevel &&
              bottomArray.map(route => (
                <Col
                  key={
                    typeof route.name === 'object'
                      ? route.name[lang]
                      : route.name
                  }
                  className={
                    'text-center p-0 ' +
                    (pathname === route.to ? 'text-primary' : '')
                  }
                  id={route.bottomID || ''}
                  onClick={() => handleRouteClick(route.to)}
                >
                  <FontAwesomeIcon icon={route.icon} size="xl" className="" />
                  <p className="mb-0">
                    <small>
                      {route.mobileText
                        ? route.mobileText[lang]
                        : typeof route.name === 'object'
                        ? route.name[lang]
                        : route.name}
                    </small>
                  </p>
                </Col>
              ))}
            {userInfos.level >= teamLevel &&
              TEAMbottomArray.map(route => (
                <Col
                  key={
                    typeof route.name === 'object'
                      ? route.name[lang]
                      : route.name
                  }
                  className={
                    'text-center p-0 ' +
                    (pathname === route.to ? 'text-primary' : '')
                  }
                  id={route.bottomID || ''}
                  onClick={() => handleRouteClick(route.to)}
                >
                  <FontAwesomeIcon icon={route.icon} size="xl" className="" />
                  <p className="mb-0">
                    <small>
                      {route.mobileText
                        ? route.mobileText[lang]
                        : typeof route.name === 'object'
                        ? route.name[lang]
                        : route.name}
                    </small>
                  </p>
                </Col>
              ))}
          </>
        )}
        {/* END VERSION 2 */}
        {/* VERSION 1: WITH CENTRAL BUTTON */}
        {navbarVersion === 1 && (
          <>
            {firstTwoSXRoutes.map(route => (
              <Col
                key={
                  typeof route.name === 'object' ? route.name[lang] : route.name
                }
                className={
                  'text-center p-0 ' +
                  (pathname === route.to ? 'text-primary' : '')
                }
                onClick={() => handleRouteClick(route.to)}
              >
                <FontAwesomeIcon icon={route.icon} size="xl" className="" />
                <p className="mb-0">
                  <small>
                    {route.mobileText
                      ? route.mobileText[lang]
                      : typeof route.name === 'object'
                      ? route.name[lang]
                      : route.name}
                  </small>
                </p>
              </Col>
            ))}
            {frontendRoutes.map(
              route =>
                route.inBottomNavbar &&
                route.inBottomCenter && (
                  <Col
                    xs={3}
                    key={
                      typeof route.name === 'object'
                        ? route.name[lang]
                        : route.name
                    }
                    className={
                      'text-center p-0 ' +
                      (pathname === route.to ? 'text-primary' : '')
                    }
                    onClick={() => handleRouteClick(route.to)}
                  >
                    <FontAwesomeIcon icon={route.icon} size="2x" className="" />
                    <p className="mb-0">
                      <small>
                        {route.mobileText
                          ? route.mobileText[lang]
                          : typeof route.name === 'object'
                          ? route.name[lang]
                          : route.name}
                      </small>
                    </p>
                  </Col>
                )
            )}
            {firstTwoDXRoutes.map(route => (
              <Col
                key={
                  typeof route.name === 'object' ? route.name[lang] : route.name
                }
                className={
                  'text-center p-0 ' +
                  (pathname === route.to ? 'text-primary' : '')
                }
                onClick={() => handleRouteClick(route.to)}
              >
                <FontAwesomeIcon icon={route.icon} size="xl" className="" />
                <p className="mb-0">
                  <small>
                    {route.mobileText
                      ? route.mobileText[lang]
                      : typeof route.name === 'object'
                      ? route.name[lang]
                      : route.name}
                  </small>
                </p>
              </Col>
            ))}
          </>
        )}
        {/* END VERSION 1 */}
        {/* INITIAL STANDARD VERSION */}
        {/* {frontendRoutes.map(
          route =>
            route.inBottomNavbar &&
            route.inBottomRight && (
              <Col
                key={route.name}
                className={
                  'text-center py-1 ' +
                  (pathname === route.to ? 'text-primary' : '')
                }
                onClick={() => handleRouteClick(route.to)}
              >
                <FontAwesomeIcon icon={route.icon} size="xl" className="" />
                <p className="mb-0">
                  <small>
                    {route.mobileText
                      ? route.mobileText[lang]
                      : typeof route.name === 'object'
                      ? route.name[lang]
                      : route.name}
                  </small>
                </p>
              </Col>
            )
        )} */}
      </Row>
    </Navbar>
  );
};

export default NavbarBottom;
