import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoPopover = ({
  Text,
  isTextHtml,
  placement,
  paddingLeft,
  classnameSize,
  autoWidth = null,
  Tag = 'div',
  ...rest
}) => {
  isTextHtml === undefined ? (isTextHtml = false) : null;
  return (
    <Tag
      style={{
        paddingLeft: paddingLeft ? paddingLeft : '3px',
        width: autoWidth ? 'auto' : null
      }}
      {...rest}
    >
      <OverlayTrigger
        key="right"
        placement={placement ? placement : 'top'}
        overlay={
          !isTextHtml ? (
            <Tooltip>{Text}</Tooltip>
          ) : (
            <Tooltip>
              <p dangerouslySetInnerHTML={{ __html: Text }}></p>
            </Tooltip>
          )
        }
      >
        <span style={{ fontSize: '14px' }}>
          <FontAwesomeIcon
            icon={'info-circle'}
            className={classnameSize ? classnameSize : 'fa-xs'}
            style={{ verticalAlign: '0.125em' }}
          />
        </span>
      </OverlayTrigger>
    </Tag>
  );
};

InfoPopover.propTypes = {
  Tag: PropTypes.string.isRequired,
  Text: PropTypes.string.isRequired,
  isTextHtml: PropTypes.bool,
  placement: PropTypes.string,
  paddingLeft: PropTypes.string,
  classnameSize: PropTypes.string,
  autoWidth: PropTypes.bool
};

export default InfoPopover;
